/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
	position: relative;
// background-color: linear-gradient(rgba($brand-second, 0.15), rgba($body-bg, .1));;
background: url(../images/homeBG.svg) no-repeat bottom right;
padding: 0rem;
border-bottom: solid .5rem $body-bg;
	
	.content-header {
		@extend .container-max;
		padding: 2rem;
	}
	
	.logo-header {
		
		>img {
			@extend .img-responsive;
			display: inline-block;
			width: 22rem;
		}
	}
}